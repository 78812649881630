<template>
  <div class="layout">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">Managment</h3>
      <div class="flex">
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
          <label for="search_organizations" class="sr-only">Search</label>
          <div class="flex rounded-md shadow-sm">
            <div class="relative flex-grow focus-within:z-10">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <SearchIcon class="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                v-model="search"
                name="search_organizations"
                id="search_organizations"
                class="search hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
                placeholder="Search"
              />
            </div>
            <Menu>
              <div class="relative">
                <MenuButton
                  type="button"
                  class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                >
                  <SortAscendingIcon class="h-5 w-5 text-gray-400" />
                  <span class="ml-2">Sort</span>

                  <ChevronDownIcon
                    class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                  />
                </MenuButton>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-out"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="focus:outline-none absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="sort-menu"
                  >
                    <div class="py-1" role="none">
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Name</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date modified</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date created</a
                        >
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </div>
            </Menu>
          </div>
        </div>
        <button
          type="button"
          @click="drawer.create = true"
          class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
        >
          Create
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";

import {
  SearchIcon,
  SortAscendingIcon,
  ChevronDownIcon,
} from "@heroicons/vue/outline";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

export default defineComponent({
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    SearchIcon,
    SortAscendingIcon,
    ChevronDownIcon,
  },

  setup() {
    const state = reactive({
      count: 0,
    });

    return {
      ...toRefs(state),
    };
  },
});
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
}
</style>
